import * as React from "react"

import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"
import { heroBackground, heroImageClass, heroTitleCont, educationImage, trainingContainer,trainingTitle, courseButton } from "../styles/education.module.scss"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from 'gbimage-bridge';

const Education = (pageQuery) => {
    const heroImage = getImage(pageQuery.data.heroImage.childImageSharp.gatsbyImageData)

    return (
        <Layout>
            <Seo title="Education" />
            <section>
                <div className={heroBackground}>
                    {/* <div className={heroImageClass}>
                    </div> */}

                    <BgImage image={heroImage} className={heroImageClass}>
                        <div></div>
                    </BgImage>

                    <div className={heroTitleCont}>
                        <Container>
                            <Row>
                                <Col xs={12} md={9} lg={6}>
                                    <h1>
                                        Training The Leaders of Tomorrow
                </h1>
                                    <p>
                                        A key pillar of the Charles Wolfson Centre is to train the next generation of world class plastic and reconstructive surgeons of tomorrow.
                </p>
                                    <div className="d-flex">
                                        <Button className={`${courseButton} white-button rounded-button`} style={{ marginRight: '1rem' }}>Masters Degree</Button>
                                        <Button className={`${courseButton} white-button rounded-button`} >Surgical Skills Course</Button>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </div>
            </section>

            <section style={{ background: 'white' }}>
                <svg width="100%" height="150" viewBox="0 0 500 120" preserveAspectRatio="none" className="background-svg" style={{ marginTop: '-7rem' }}>
                    <path d="M0,120 L0,80 Q250,0 500,80 L500,120 Z" fill="white" />
                </svg>
                <Container className={trainingContainer}>
                    <Row className="d-xs-flex d-lg-none" style={{position: 'relative'}}>
                        <Col>
                            <div className={trainingTitle}>
                                <h2>Our Training</h2>
                                <div className="title-highlight" style={{
                                    width: '30%',
                                    marginBottom: '2.5rem'
                                }}></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg={6} style={{ display: 'flex', justifyContent: 'center' }} >
                            <StaticImage
                                src="../images/education-scene.png"
                                formats={["AUTO", "WEBP"]}
                                className={educationImage}
                            />
                        </Col>
                        <Col lg={6}>
                            <div className="d-none d-lg-block">
                                <h2>Our Training</h2>
                                <div className="title-highlight" style={{
                                    width: '30%',
                                    marginBottom: '2.5rem'
                                }}></div>
                            </div>
                            <p>
                                Education is an integral step in improving patient care and promoting clinical excellence. Our bespoke postgraduate courses, research modules and clinical skills sessions, organised through the Charles Wolfson Centre and our collaborating organisations, will promote advances in clinical practice, academia and critical thinking. Helping the doctors of the future ask and answer the key questions in plastics.<br /><br />Our graduates will be equipt with the technical, analytical and creative skills to embark on a successful career in clinical and academic plastic and reconstructive surgery and provide their patients with excellent care.
                        </p>
                        </Col>
                    </Row>
                </Container>

                <svg width="100%" height="150" viewBox="0 0 500 120" preserveAspectRatio="none" className="background-svg" style={{ background: '#FAFAFA' }}>
                    <path d="M0,0 L0,40 Q250,120 500,40 L500,0 Z" fill="white" />
                </svg>
            </section>
            <section>
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} md={5}>
                            <div>
                                <h2 className="text-center">Our Courses</h2>
                                <div className="title-highlight" style={{
                                    width: '30%',
                                    margin: 'auto',
                                    marginBottom: '3.5rem'
                                }}></div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <div className="card" style={{ padding: 0, marginBottom: '2rem' }}>
                                <StaticImage
                                    src="../images/masters-surgery.png"
                                    formats={["AUTO", "WEBP"]}
                                />

                                <div style={{ padding: '2rem' }}>
                                    <h3>Burns, Plastics and Reconstructive Surgery MSc</h3>
                                    <p>
                                        Run by University College London, this is the only MSc programme in burns, plastic and reconstructive surgery currently available. It aims to impart a core knowledge and understanding of reconstructive surgery. Housed within the Division of Surgery and Interventional science at University College London, the MSc was developed and is directed by Professor Peter Butler. The programme helps to equip healthcare professional and scientists with the research skills and translations knowledge necessary for clinically applied research in this field.<br /><br />Students will work with renowned consultants at the Royal Free Hospital and other prestigious specialist centres and have exposure in innovative and emerging technologies. The opportunities this programme offers its students to further their research at MD/PhD level and clinical careers is unparalleled.<br /><br />
                                    Find full details on entry criteria and how to apply on the <a href="https://www.ucl.ac.uk/surgery/courses/postgraduate/pg-taught/msc-burns" target="_blank" rel="noreferrer">UCL Website</a>
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="card" style={{ padding: 0, marginBottom: '2rem' }}>
                                <StaticImage
                                    src="../images/surgical-skills.png"
                                    formats={["AUTO", "WEBP"]}
                                />
                                <div style={{ padding: '2rem' }}>
                                    <h3>Plastic & Reconstructive Surgical Skills Course</h3>
                                    <p>
                                        The Charles Wolfson Centre is proud to offer a novel practical skills course in plastic surgery, for no cost. Over the two-day course, our doctors will learn the basic practical skills required to kick start their surgical career. The course is led by senior registrars and consultants, offering the opportunity for focused expert feedback.
                                <br /><br />Based at the Royal Free Hospital, Hampstead in collaboration with University College London and the CWC, quite uniquely this course provides extensive high quality pre-course material of online videos and lectures enabling our doctors to explore and develop rapidly during the in-person course. During the course our doctors will receive a handbook to facilitate the course and guide post course revision allowing development of your surgical skills post course.
                                <br /><br />More information on entry criteria and how to apply for the course can be found <Link to="/plastic-and-reconstructive-surgical-skills-course">here</Link>.
                                </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export const pageQuery = graphql`
  {
  heroImage: file(relativePath: {eq: "UCL.jpeg"}) {
    childImageSharp {
			gatsbyImageData(
        formats: [AUTO, WEBP]
        placeholder: BLURRED
      )
    }
  }
}
`

export default Education
